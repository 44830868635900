.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary-white);
  z-index: 10000;
  padding: 0 5%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.leftContainer a{
  font-family: "Paytone One";
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  color: var(--dark-blue);
  text-decoration: none;
}

.centerContainer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerContainer ul li {
  list-style-type: none;
  margin: auto;
}

.centerContainer ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 16px;
  text-decoration: none;
  color: inherit;
}

.centerContainer ul li a.active {
  color: var(--medium-blue);
  border-bottom: 3px solid var(--medium-blue);
}

.rightContainer a {
  margin-left: 16px;
}

.rightContainer .icon {
  width: 32px;
  height: 32px;
  color: var(--dark-blue);
}

.rightContainer .icon:hover {
  color: var(--light-blue);
}

@media screen and (max-width: 992px) {
  .container {
    padding: 0 1%;
  }
}

@media screen and (max-width: 899px) {
  .container {
    justify-content: start;
    height: 80px;
  }
  .leftContainer a {
    margin-left: 16px;
    font-size: 32px;
    text-align: center;
    align-self: center;
  }
  .centerContainer {
    display: none;
  }
  .rightContainer {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .toggleBtn {
    display: none;
  }
}
