.footerContainer {
  color: var(--primary-white);
  background-color: var(--dark-blue);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding: 30px 20px;
  width: 100%;
}

.leftContainer,
.centerContainer,
.rightContainer {
  width: 25%;
  padding: 20px;
  border-bottom: 1px solid var(--light-blue);
  border-top: 1px solid var(--light-blue);
}

.leftContainer h3 {
  text-align: center;
  margin-bottom: 1rem;
}


.leftContainer ul {
  display: inline-block;
  width: 30%;
  margin: 0 10% 0 10%;
  text-align: center;
}

.leftContainer ul li {
  list-style-type: none;
  margin: 0 0 14px;
  font-weight: 500;
  font-size: 1.1rem;
  line-height:1.5em;
  /* float:left;
  display:block;
  width:50%; */
}

.leftContainer ul li:hover {
  font-weight: 600;
  text-shadow: 3px 3px 6px var(--medium-blue);
}

.leftContainer ul li a {
  text-decoration: none;
  color: inherit;
}

.centerContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Paytone One";
  font-size: 2rem;
}

.centerContainer .socialIcons {
  margin-top: 20px;
}

.centerContainer .icon  {
  width: 2rem;
  margin: 0px 10px;
  color: var(--primary-white);
}

.rightContainer h3 {
  text-align: center;
}

.rightContainer .contactElement {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}

.rightContainer .contactIcon {
  width: 24px;
  height: auto;
  margin-right: 10px;
}

.rightContainer a {
  text-decoration: none;
  color: var(--primary-white);
}

@media screen and (max-width: 768px) {
  .footerContainer {
    align-items: center;
    flex-direction: column;
  }

  .footerContainer div {
    width: 100%;
  }
}
