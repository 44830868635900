.container {
  width: 100%;
}

/*@media screen and (max-width: 1200px) {
  .container {
    max-width: 992px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    max-width: 768px;
  }
} */
