.title {
  font-size: clamp(1.2rem, 10vw, 2.5rem);
  text-transform: uppercase;
  font-family: var(--header-font);
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 3px;
  text-align: center;
  position: relative;
}

.form {
  margin-top: 3rem;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.firstRow input {
  height: 32px;
  padding: 12px;
}

.firstRow .name {
  margin-right: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.firstRow .email {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.secondRow {
  margin-bottom: 16px;
}

.secondRow .subject {
  display: flex;
  flex-direction: column;
}

.secondRow input {
  width: 100%;
  height: 32px;
  padding: 12px;
}

.thirdRow {
  margin-bottom: 16px;
  margin-top: 5px;
}

.thirdRow textarea {
  width: 100%;
  height: 250px;
  padding: 12px;
}

.thirdRow .message {
  display: flex;
  flex-direction: column;
}

.submitBtn {
  padding: 12px 28px;
  border: none;
  background-color: var(--medium-blue);
  color: var(--primary-white);
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: var(--light-blue);
}

.submitBtn:disabled {
  background-color: gray;
  cursor: wait;
}

.errorMessage {
  color: red;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .firstRow {
    flex-direction: column;
    align-items: stretch;
  }

  .firstRow input {
    width: 100%;
    margin: 0;
  }

  .firstRow .name {
    margin: 0;
    margin-bottom: 1rem;
  }
}
