.sideDrawer {
  height: 100%;
  background: var(--primary-white);
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: -6px;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sideDrawer.open {
  transform: translateX(0);
}

.sideDrawer h2 {
  font-family: var(--header-font);
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin: 3rem 2rem;
  position: relative;
}


.sideDrawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
}

.sideDrawer li {
  margin: 0.5rem 2rem;
}

.sideDrawer a {
  text-decoration: none;
  color: var(--medium-blue);
  font-size: 1.5rem;
}

.socialMedia .icon {
  width: 32px;
  height: 32px;
  color: var(--dark-blue);
}


.sideDrawer .socialMedia {
  margin: 3rem 2rem;
  display: flex;
  justify-content: space-around;
}

@media screen and (min-width: 900px) {
  .sideDrawer {
    display: none;
  }
}
