.faqContainer h2{
  text-align: center;
  font-family: var(--header-font);
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 3rem;
}

details {
  max-width: 992px;
  border-bottom: 1px solid rgb(73, 67, 67);
  border-radius: 3px;
  margin:2rem auto;

}

summary {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem;
  margin-bottom: 1rem;  
  text-align: left;
  cursor: pointer;
  position: relative;
}

.faq_content {
  margin-bottom: 2rem;
  padding:0 1rem 0 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}

details > summary::after {
  position: absolute;
  /* down arrow icon */
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z'/%3E%3C/svg%3E");;
  width: 12px;
  right: 20px;
}
details[open] > summary::after {
  position: absolute;
  /* up arrow icon */
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z'/%3E%3C/svg%3E");
  width: 12px;
  right: 20px;
}
details > summary::-webkit-details-marker {
  display: none;
}

details[open] summary ~ * {
  animation: sweep .5s ease;
}
@keyframes sweep {
  0%    { margin-top: -10px}
  100%  {margin-top: 0px}
}