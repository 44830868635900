.slider {
  width: 97%;
  margin: auto;
}

.slider .title {
  margin-top: 100px;
  font-size: clamp(1.2rem, 10vw, 4rem);
  text-transform: uppercase;
  font-family: var(--header-font);
  font-size: 50px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 3px;
  position: relative;
}


.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container .card {
  position: relative;
  overflow: hidden;
  height: 380px;
  width: 320px;
  transition: height 0.5s;
}

.container .card:hover {
  height: 440px;
}

.container .card .imgBx {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  z-index: 10000;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.container .card:hover .imgBx {
  top: 0%;
  transform: translateY(0%);
}

.container .card .imgBx img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.container .card .contentBx {
  position: absolute;
  bottom: 10%;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container .card:hover .contentBx {
  height: 210px;
}

.container .card .contentBx h2 {
  text-transform: uppercase;
  position: relative;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1px;
  color: #000;
  margin-bottom: 8px;
  padding: 8px 14px;
}

.container .card .contentBx p {
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:hover .contentBx p {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card a {
  display: inline-block;
  padding: 10px 20px;
  background: var(--medium-blue);
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  transition: 0.5s;
  margin-top: 0;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 50px);
}

.container .card:hover a {
  opacity: 1;
  transform: translate(-50%, 0px);
  transition-delay: 0.75s;
}


