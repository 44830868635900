.videoDetailContainer {
    max-width: 992px;
    margin: auto;
    padding: 0 15px;
}


.youtubeVideoContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
}

.youtubeVideoContainer::after {
    display: block;
    content: "";
    padding-top: 56.25%;
}

.youtubeVideoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.videoDetailContainer .videoTexts{
    margin: 10px 0 0 0;
}

.videoDetailContainer .title {
    margin: 10px 0 0 0;
}

.videoDetailContainer .date {
    margin: 10px;
    text-align: right;
}

.videoDetailContainer .description {
    margin: 10px 0;
    letter-spacing: 0.1px;
}

.videoDetailContainer .info {
    font-size: 0.8;
    margin-top: 30px;
}

/* TODO */
.videoDetailContainer .info a{
    text-decoration: none;
    color: rgb(75, 140, 200);
}