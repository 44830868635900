.container {
  display: flex;
  justify-content: center;
}

.leftContainer {
  padding: 2rem 5rem 5rem 0;
}

.leftContainer iframe {
  width: 600px;
  height: 450px;
}

.contactInfo {
  margin-bottom: 2rem;
  width: 600px;
}

.contactInfo h3 {
  margin-bottom: 1rem;
}

.contactInfo p {
  margin-bottom: 1rem;
}

.rightContainer {
  padding: 2rem 0 5rem 5rem;
}

.splitter {
  width: 5px;
  border-left: 1px solid black;
  border-right: 1px solid var(--light-blue);
}

@media screen and (max-width: 1200px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .leftContainer {
    padding: 0;
  }

  .leftContainer iframe {
    width: 450px;
    height: 300px;
  }

  .contactInfo {
    width: 450px;
  }

  .rightContainer {
    padding: 0;
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 768px) {
  .leftContainer iframe {
    width: 300px;
    height: 250px;
  }

  .contactInfo {
    width: 300px;
  }

  .rightContainer {
    width: 300px;
  }
}
