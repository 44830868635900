.videosContainer .title{
  text-align: center;
  font-family: var(--header-font);
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 3px;
}

.searchBar {
  margin: 2rem 3rem 0 3rem;
  float: right;
}
.searchBar div {
  position: relative;
}
.searchBar input {
  padding: 0.5rem 2rem;
  width: 360px;
  height: 36px;
  border: 0px solid gray;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25), 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.searchBar .icon {
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
  padding-left: 0.5rem;
  color: rgb(70, 70, 70);
}

.list {
  margin-top: 6rem;
}
@media screen and (max-width: 768px) {
  .searchBar {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    float: unset;
  }

  .list {
    margin-top: unset;
  }
}

