.link {
    text-decoration: none;
    color: inherit;
}

.videoContainer {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin: 3rem 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 2rem;
    transition: ease 0.3s;
    background-image: linear-gradient(to right, rgb(247, 247, 247) , rgb(255, 255, 255));

}

.leftContainer {
    margin-right: 1rem;
}

.leftContainer img {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.rightContainer h3 {
    font-size: 1.3rem;
    margin-bottom: 8px;
}

/* TODO : fix overflow */
.rightContainer {
    overflow: auto;
}

.videoContainer:hover {
    transform: scale(1.05);
}



@media screen and (max-width: 992px) {
    .rightContainer {
        width: 100%;
        text-align: center;
    }

    .rightContainer p {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .videoContainer {
        flex-direction: column;
    }

    .leftContainer {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .leftContainer img {
        width: 100%;
    }
}