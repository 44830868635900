@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");


:root {
  /* variables for colors */
  --dark-blue: #2e424d;
  --medium-blue: #5b8291;
  --light-blue: #98dad9;
  --primary-white: #ffffff;

  /* variables for fonts */
  --header-font: 'Montserrat';

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* max-width: 1200px; */
  /* margin: auto; */
  font-family: "Source sans pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media screen and (max-width: 1200px) {
  body {
    max-width: 992px;
  }
} */

/* @media screen and (max-width: 992px) {
  body {
    max-width: 768px;
  }
} */
/* 
@media screen and (max-width: 768px) {
  body {
    max-width: 992px;
  }
} */
