.pubContainer {
  margin: 0px 10px;
}

.pubContainer .card {
  max-width: 992px;
  margin: 2rem auto;
  border-radius: 3px;
  padding: 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25), 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.pubContainer .title {
  font-size: clamp(1.2rem, 10vw, 4rem);
  text-transform: uppercase;
  font-family: var(--header-font);
  font-size: 60px;
  text-align: center;
  font-weight: 700;
  position: relative;
  letter-spacing: 3px;
}

.pubContainer .card .pubTitle {
  padding-left: 5px;
  margin-bottom: 5px;
}

.pubContainer h2 {
  font-family: var(--header-font);
  margin: 75px auto 0 auto;
  padding-left: 1rem;
  max-width: 992px;
}


.pubContainer .card .info {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 10px;
}

.pubContainer .card .info .leftP {
  width: 45%;
  text-align: left;
}

.pubContainer .card .info .rightP {
  width: 45%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

/* TODO : Bu kod neden sadece .rightP icindeki p leri etkiliyor */
.pubContainer .card .info p {
  margin: auto 0 auto 0
}

.pubContainer .card .info .rightP .icon {
  margin: auto 0 auto 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.pubContainer .card .info .splitter {
  width: 3px;
  margin: 0px 10px;
  border-left: 1px solid rgb(95, 93, 93);
}


@media screen and (max-width: 768px) {
  .pubContainer .card .pubTitle {
    padding-left: unset;
    margin-bottom: 5px;
  }

  .pubContainer .card .info {
    width: 100%;
    display: unset;
    justify-content: unset;
  }

  .pubContainer .card .info .leftP {
    margin: 10px 0px;
    width: 85%;
  }

  .pubContainer .card .info .rightP {
    padding-left: 15%;
    width: 100%;
    text-align: right;
  }

  .pubContainer .card .info .splitter {
    display: none;
  }
}