.container {
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftContainer img {
  border: 1px solid black;
  border-radius: 50%;
  width: 300px;
  margin-bottom: 2rem;
}

.leftContainer h1 {
  margin-bottom: 1rem;
  font-size: 45px;
  font-weight: 700;
}

.rightContainer {
  width: 750px;
}

.rightContainer h1 {
  font-size: clamp(1.2rem, 10vw, 4rem);
  font-family: var(--header-font);
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  letter-spacing: 3px;
  /* font-weight: 700; */
  position: relative;
}


.rightContainer h2 {
  margin: 2.5rem 0 1rem 0;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: var(--header-font);
  font-weight: 700;
}

.rightContainer p,
.rightContainer li {
  text-align: justify;
 line-height: 1.5rem;
}

.splitter {
  width: 5px;
  border-left: 1px solid black;
  border-right: 1px solid var(--light-blue);
}

@media screen and (max-width: 1200px) {
  .rightContainer {
    max-width: 600px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .leftContainer {
    margin-bottom: 4rem;
  }

  .rightContainer {
    max-width: 90%;
    margin-bottom: 3rem;
  }

  .rightContainer h1 {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .splitter {
    border-left: 0;
    border-right: 0;
  }
}
