@keyframes right-to-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes left-to-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


.container {
  background-image: url(/src/assets/img/subtle-prism.svg);
  background-size: cover;
  width: 99.374vw;
  height: clamp(680px, 100vh - 80px, 100vh - 80px);
  margin-left: calc(((1200px - 100vw) / 2));
  margin-top: -80px;
}

.content {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 80%;
  max-width: 1100px;
  margin: 0 auto;
}

.content .photo {
  grid-area: 1fr;
  align-self: end;
  justify-self: end;
  height: 600px;
  transition: all 0.5s;
  animation: right-to-left 1s; 
}

.leftContainer {
  grid-area: 1fr;
  width: 100%;
  padding-left: 3rem;
  animation: left-to-right 1s;
}

.leftContainer .name {
  font-size: 2rem;
}

.leftContainer .job {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.leftContainer .description {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.leftContainer .firstButton {
  display: inline-block;
  margin-right: 2rem;
  border: none;
  background-color: var(--dark-blue);
  color: var(--primary-white);
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.1s ease-in;
  border-radius: 3px;
}

.firstButton:hover {
  background-color: var(--medium-blue);
}

.leftContainer .secondButton {
  display: inline-block;
  border: none;
  background-color: var(--light-blue);
  color: var(--dark-blue);
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.1s ease-in;
  border-radius: 3px;
}

.secondButton:hover {
  background-color: var(--medium-blue);
  color: var(--primary-white);
}





@media screen and (max-width: 1200px) {
  .container {
    margin-left: calc(((992px - 100vw) / 2));
  }

  .content .leftContainer {
    justify-self: center;
    align-self: center;
    text-align: center;
    padding: 0;
  }
  
  .content .photo {
    height: unset;
    width: 75%;
    align-self: center;
  }

  .leftContainer .firstButton {
    margin: 0 0 1rem 0;
  }

  .leftContainer .secondButton {
    margin-left: 20px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin-left: calc(((768px - 100vw) / 2));
  }

  .content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }

  .content .photo {
    width: unset;
    height: 40%;
    align-self: center;
  }

}

@media screen and (max-width: 768px) {
  .container {
    margin-left: 0;
    height: 800px;

  }
  
  .leftContainer .job {
    font-size: 2rem;
  }
}

@media screen and (max-width: 488px) {
  .leftContainer .secondButton {
    margin-left: unset;
  }
}
