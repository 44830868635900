.container {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.content {
  padding: 160px 0 400px 0;
  max-width: 1200px;
  margin: auto;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .content {
    max-width: 992px;
  }
}
@media screen and (max-width: 992px) {
  .content {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    max-width: 100%;
    padding-bottom: 700px;
  }
}
